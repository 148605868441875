<template lang="pug">
page.omen(:show-header='false', :ga-event-category='gaEventCategory', :ga-event-label='gaEventLabel')
  //- Content
  template(slot='content')
    page-section.omen-section.omen-section--banner(:fullWidth='true', align='center')
      template(slot='content')
        .banner-img
        .banner-img-bg

    //- Main
    page-section#main.omen-section.omen-section--enter.page-pt-md-alt.page-pb-md(align='center')
      template(slot='content')
        //- Loading
        loading-icon.page-mt-lg-alt.page-mb-xxl(v-if='isLoading', size='lg', color='omen-black')

        //- Error
        template(v-else-if='isError') Sorry, there was an error, please try refreshing the page.

        //- No Giveaways
        template(v-else-if='!giveawayList.length')
          h1.page-pb-md.fw-black.color-omen-black.fs-xl(class='sm:fs-xxl lg:fs-mega xl:fs-huge') Coming Soon

        //- Has Giveaways
        template(v-else)
          //- Active Giveaway list
          template(v-if='activeGiveaways.length')
            h1.h3.text-left(:class='sectionTitleClasses') Active Giveaways
            giveaway-list(
              v-bind='commonListProps',
              :list='activeGiveaways',
              :show-action-button='true',
              action-button-variant='omen-black-active',
              action-button-title='ENTER GIVEAWAY',
              action-button-margin-top='2',
              action-button-font-size='sm'
            )

          //- Upcoming Giveaway list
          div(v-if='upcomingGiveaways.length', :class='{ "page-mt-lg": activeGiveaways.length }')
            h3.text-left(:class='sectionTitleClasses') Upcoming Giveaways
            giveaway-list(v-bind='commonListProps', :list='upcomingGiveaways', :show-action-button='false')

          //- Ended Giveaway list
          div(
            v-if='endedGiveaways.length',
            :class='{ "page-mt-lg": activeGiveaways.length || upcomingGiveaways.length }'
          )
            h3.text-left(:class='sectionTitleClasses') Recently Ended
            giveaway-list(
              v-bind='commonListProps',
              :list='endedGiveaways',
              :show-action-button='true',
              action-button-variant='omen-black-ended',
              action-button-title-ended='ENDED',
              action-button-margin-top='2',
              action-button-font-size='sm'
            )

  template(slot='content')
    page-section.omen-section.omen-section--video.page-pb-md.page-pt-lg(:fullWidth='false', align='center')
      template(slot='content')
        youtube-video(:youtube-link='youtubeLink')
          .page-pt-md(slot='append')
            h3.fw-black.color-omen-black.fs-xxxl(class='sm:fs-mega md:fs-huge lg:fs-huge-alt')
              | LEARN MORE ABOUT
              br
              | OMEN GAMING HUB
            b-btn.btn-lg(variant='omen-black', :href='downloadOmenLink', target='_blank')
              span.copy DOWNLOAD ON WINDOWS

  //- Footer
  template(slot='footer')
    base-footer(:ga-event-category='gaEventCategory', :ga-event-label='gaEventLabel', logo-color='black')
</template>
<script>
import BaseFooter from '../components/BaseFooter'
import YoutubeVideo from '../components/YoutubeVideo.vue'
import allHostGiveawaysMixin from '../mixins/base/all-host-giveaways'

export default {
  name: 'Omen',
  mixins: [allHostGiveawaysMixin],
  data() {
    return {
      pageMetaTitle: 'Omen Gaming Hub',
      hostId: 1233080,
      maxEndedDays: 365,
      excludedIdTokens: ['bo0wTm_', 's9DPpsl'],
      youtubeLink: 'https://www.youtube.com/embed/IGrEZYPVXIc',
      downloadOmenLink: 'https://www.microsoft.com/en-us/p/omen-gaming-hub/9nqdw009t0t5?cid=surfgiveaways',
      sectionTitleClasses: 'sm:page-pl-md fw-black color-omen-black fs-xl sm:fs-xxl lg:fs-mega xl:fs-huge'
    }
  },
  computed: {
    commonListProps() {
      return {
        listJustifyStart: true,
        showPrizes: false,
        showState: false,
        titleSize: 'lg',
        titleAlign: 'center',
        tileSize: 'full',
        tileSizeMd: 'half',
        gaEventCategory: this.gaEventCategory,
        gaEventLabel: this.gaEventLabel
      }
    }
  },
  components: {
    BaseFooter,
    YoutubeVideo
  }
}
</script>
<style lang="scss" src="../assets/themes/hp-omen/scss/main.scss" />
