import fetchGiveawaysMixin from '../fetch-giveaways'
import gaEventMixin from '../ga_event'

export default {
  mixins: [fetchGiveawaysMixin, gaEventMixin],
  data() {
    return {
      pageMetaTitle: '',
      isLoading: true,
      isError: false,
      hostId: null,
      maxEndedDays: 90,
      giveawayList: [],
      activeGiveaways: [],
      upcomingGiveaways: [],
      endedGiveaways: [],
      excludedIdTokens: [],
      giveawayStateInterval: null
    }
  },
  async created() {
    try {
      const giveawayList = await this.fetchGiveawaysByHost(this.hostId, this.maxEndedDays)
      this.giveawayList = this.sortGiveawaysByStart(
        giveawayList.filter((g) => !this.excludedIdTokens.includes(g.idToken))
      )
      this.giveawayStateChecker()
      this.giveawayStateInterval = setInterval(this.giveawayStateChecker, 1000)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      this.isError = true
    } finally {
      this.isLoading = false
    }
  },
  beforeDestroy() {
    clearInterval(this.giveawayStateInterval)
  },
  methods: {
    jumpTo(id) {
      this.trackEvent(`jump_to_${id}`)
      this.$helpers.scrollToId(id)
    },
    trackGiveawayClick(idToken) {
      this.trackEvent(`to_giveaway__${idToken}`)
    },
    getGiveawayByIdToken(idToken) {
      return this.giveawayList.find((g) => g.idToken === idToken)
    },
    giveawayStateChecker() {
      this.activeGiveaways = this.sortGiveawaysByStart(this.giveawayList.filter((g) => g.isActive))
      this.upcomingGiveaways = this.sortGiveawaysByStart(this.giveawayList.filter((g) => g.isUpcoming))
      this.endedGiveaways = this.sortGiveawaysByEnd(this.giveawayList.filter((g) => g.isEnded))
    },
    sortGiveawaysByStart(giveawayList) {
      return giveawayList.sort((a, b) => {
        if (a.startTime === b.startTime) {
          return a.title > b.title ? 1 : -1
        }

        return a.startTime > b.startTime ? -1 : 1
      })
    },
    sortGiveawaysByEnd(giveawayList) {
      return giveawayList.sort((a, b) => {
        if (a.expiration === b.expiration) {
          return a.title > b.title ? 1 : -1
        }

        return a.expiration > b.expiration ? -1 : 1
      })
    }
  },
  metaInfo() {
    return {
      title: this.pageMetaTitle,
      titleTemplate: '%s | Surf Giveaways'
    }
  }
}
